<template>
  <div id="accredit_navbar">
    <div :class="back?'navbar':'navbar_r'">
      <div v-if="back" class="navbar-back" @click="BackClick"><i v-if="back" class="el-icon-arrow-left"></i></div>
      <div class="navbar-content">{{title}}</div>
      <div v-if="back" style="width:16px;height:21px"></div>
    </div>
    <div style="width:100%;height:50px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  methods: {
    BackClick() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#accredit_navbar{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(218, 218, 218, 1);
  position: fixed;
  top: 0;
  z-index: 999;
}
.navbar_r {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(218, 218, 218, 1);
  position: fixed;
  top: 0;
  z-index: 999;
}
.navbar-back {
  position: relative;
  margin-left: 10px;
}
.navbar-content {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
</style>