<template>
    <div style="width:100%;">
        <navbar title='查看证书' :back='true'></navbar>
        <canvas id="mycanvas" class="my-canvas"></canvas>
        <img :src="image" @click="Flag" class="img">
        <div :class="mymoveState?'float':'float-reverse'">
            长按图片可保存到本地
        </div>
    </div>
</template>

<script>
import navbar from "COMPS/accredit_navbar.vue";
let moment = require("moment");
export default {
    data() {
        return {
            mymoveState: false,
            image: "",
            SerialNumber: "",
            authorized: "",
            Licensed_Project: "",
            Licensed_Products: [],
            Licensed_beginTime: "",
            Licensed_endTime: "",
        };
    },
    components: {
        navbar,
    },
    created() {
        let data = this.$route.query.accreditData;
        console.log(data);
        this.SerialNumber = data.impowerCode;
        this.authorized = data.Company_Individual;
        this.Licensed_Project = data.Licensed_Project;
        this.Licensed_Products = data.Licensed_Products;
        this.Licensed_beginTime = moment(data.Licensed_beginTime).format(
            "YYYY年MM月DD日"
        );
        this.Licensed_endTime = moment(data.Licensed_endTime).format(
            "YYYY年MM月DD日"
        );
    },
    mounted() {
        this.manageAccreditData();
        this.Flag();
    },
    methods: {
        manageAccreditData() {
            let canvas = document.getElementById("mycanvas"); //创建canvas
            let context = canvas.getContext("2d"); //创建画布
            let Licensed_Products = this.Licensed_Products;
            canvas.width = 375 * 3;
            canvas.height = 420 * 3 + 300 * 3 + Licensed_Products.length * 60;
            console.log(canvas.height);
            context.beginPath();
            context.rect(5, 5, canvas.width - 10, canvas.height - 10); // 绘制矩形
            context.fillStyle = "white"; // 填充颜色
            context.lineWidth = 5;
            context.strokeStyle = "rgba(234, 184, 122, 1)"; // 描边
            context.fill();
            context.stroke();

            // context.strokeText("授权编号:" + this.SerialNumber, 670, 350);
            context.beginPath();
            context.font = "30px Arial";
            context.fillStyle = "black"; // 填充颜色
            context.fillText("授权编号:" + this.SerialNumber, 670, 350);
            context.fillText("授权公司: ", 60, 460);
            context.fillText("被授权公司/个人:", 60, 520);
            let content1 =
                "本公司 慧之乳业（青岛）有限公司 是 WDOM 渥康 在中国大陆地区唯一负责 WDOM 渥康 品牌产品销售、推广、分销的运营商，兹授权  " +
                this.authorized +
                " 作为我公司(即以上授权公司)在 " +
                this.Licensed_Project +
                " 相关工作中销售 WDOM 渥康 品牌的产品，并享有该品牌及其产品的销售和宣传推广权利;并确认以下签章为真实有效。";

            this.writeTextOnCanvas(context, 50, 65, content1, 60, 620);

            context.fillText("授权情况如下:", 60, 910);
            context.fillText("1.授权平台/项目:", 60, 970);
            context.fillText("2.授权产品型号: ", 60, 1030);

            this.underline(context, "慧之乳业（青岛）有限公司", 195, 465);
            this.underline(context, this.authorized, 295, 525);
            this.underline(context, this.Licensed_Project, 295, 978);
            // let Licensed_Products = this.Licensed_Products;
            // this.underline(context, Licensed_Products[0], 295, 1038);
            let y = 1035;
            for (let i in Licensed_Products) {
                this.underline(context, Licensed_Products[i], 295, y);
                y = y + 60;
            }
            context.fillText("3.授权范围: ", 60, y);
            context.fillText(" 渥康 品牌标志、产品、图片", 330, y);
            context.fillText(
                "4.授权时间: " +
                    this.Licensed_beginTime +
                    " 至 " +
                    this.Licensed_endTime +
                    "",
                60,
                y + 60
            );
            let content2 = `5.授权宣传方式:全线宣传。即:对外海报、展架、单张等线下宣传;对外网页、网页报刊、网页杂志等线上广告宣传。`;
            this.writeTextOnCanvas(context, 50, 65, content2, 60, y + 120);
            let content3 = `6.授权公司保留于授权期间内随时撤销上述授权的权利，但会提前30天以书面形式通知被授权公司，若被授权公司自授权之日起3个月内未能开展销售工作，授权公司将立即对本授权予以撤销，不再另行通知。`;
            this.writeTextOnCanvas(context, 50, 65, content3, 60, y + 230);
            this.underline(context, "WDOM", 225, y);
            context.fillText("7.本授权转授权无效。", 60, y + 390);
            context.fillText(this.Licensed_beginTime, 780, y + 1000);
            context.stroke();

            let img1 = new Image();
            let img2 = new Image();

            // canvas.height = 700 * 3 + (y + 500);
            img1.setAttribute("crossOrigin", "anonymous"); //解决图片跨域问题,也要放到赋值前，否ze大部分浏览器会报错
            img1.src = require("../../assets/images/other/Canvas-top.png");
            img1.onload = () => {
                context.drawImage(img1, 0, 0, 375 * 3, 86 * 3); // 加入图片1
                this.image = canvas.toDataURL("image/jpg");
            };

            img2.setAttribute("crossOrigin", "anonymous"); //解决图片跨域问题,也要放到赋值前，否ze大部分浏览器会报错
            img2.src = require("../../assets/images/other/Canvas-buttom.png");
            img2.onload = () => {
                context.drawImage(
                    img2,
                    0,
                    canvas.height - 550,
                    375 * 3,
                    180 * 3
                ); // 加入图片2
                this.image = canvas.toDataURL("image/jpg");
            };
        },
        Flag() {
            this.mymoveState = true;
            setTimeout(() => {
                this.mymoveState = false;
            }, 4000);
        },

        underline(context, atext, x, y) {
            context.beginPath();
            context.font = "30px Arial";
            context.lineWidth = 2;
            context.textBaseline = "bottom";
            context.strokeStyle = "#000000";
            context.fillText(atext, x, y);

            context.moveTo(x, y);
            context.lineTo(context.measureText(atext).width + x, y);
            context.stroke();
        },

        //ctx_2d        getContext("2d") 对象
        //lineheight    段落文本行高
        //bytelength    设置单字节文字一行内的数量
        //text          写入画面的段落文本
        //startleft     开始绘制文本的 x 坐标位置（相对于画布）
        //starttop      开始绘制文本的 y 坐标位置（相对于画布）
        writeTextOnCanvas(
            ctx_2d,
            lineheight,
            bytelength,
            text,
            startleft,
            starttop
        ) {
            function getTrueLength(str) {
                //获取字符串的真实长度（字节长度）
                var len = str.length,
                    truelen = 0;
                for (var x = 0; x < len; x++) {
                    if (str.charCodeAt(x) > 128) {
                        truelen += 2;
                    } else {
                        truelen += 1;
                    }
                }
                return truelen;
            }
            function cutString(str, leng) {
                //按字节长度截取字符串，返回substr截取位置
                var len = str.length,
                    tlen = len,
                    nlen = 0;
                for (var x = 0; x < len; x++) {
                    if (str.charCodeAt(x) > 128) {
                        if (nlen + 2 < leng) {
                            nlen += 2;
                        } else {
                            tlen = x;
                            break;
                        }
                    } else {
                        if (nlen + 1 < leng) {
                            nlen += 1;
                        } else {
                            tlen = x;
                            break;
                        }
                    }
                }
                return tlen;
            }
            for (var i = 1; getTrueLength(text) > 0; i++) {
                var tl = cutString(text, bytelength);
                ctx_2d.fillText(
                    text.substr(0, tl).replace(/^\s+|\s+$/, ""),
                    startleft,
                    (i - 1) * lineheight + starttop
                );
                text = text.substr(tl);
            }
        },
    },
};
</script>
<style  scoped>
.my-canvas {
    position: fixed;
    top: -99999999999px;
    left: -99999999999px;
    z-index: -99999999999;
    opacity: 0;
}
.img {
    width: 100%;
    height: auto;
}
.float {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 169px;
    height: 32px;
    position: fixed;
    top: 110px;
    background: url("../../assets/images/icon/theFlag.png");
    color: white;
    font-size: 12px;
    /* padding: 10px; */
    animation-name: mymove;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

@keyframes mymove {
    from {
        left: -169px;
    }
    to {
        left: 0px;
    }
}
.float-reverse {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 169px;
    height: 32px;
    padding: 10px;
    position: fixed;
    top: 110px;
    background: url("../../assets/images/icon/theFlag.png");
    color: white;
    font-size: 12px;
    animation-name: mymove-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes mymove-reverse {
    from {
        left: 0px;
    }
    to {
        left: -169px;
    }
}
</style>
